import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as features from '../features';
import { UserEffects } from '../user/user.effects';
import { AppEffects } from './app.effects';
import { reducer } from './app.reducer';
import { EventLogEffects, ObservationsEventLogEffects } from './effects';

const eventLogs = [EventLogEffects, ObservationsEventLogEffects];

@NgModule({
  imports: [
    EffectsModule.forFeature([...eventLogs, AppEffects, UserEffects]),
    StoreModule.forFeature(features.APP, reducer),
  ],
})
export class AppStoreModule {}
