import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@core/services';
import { downloadJSON, getStorageFolder } from '@globals';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, from, map, of, switchMap } from 'rxjs';
import { DOWNLOAD_OBSERVATION_ACTIONS, OBSERVATION_UI_ACTIONS } from './observation.actions';

@Injectable()
export class ObservationEffects {
  removeObservation$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OBSERVATION_UI_ACTIONS.remove),
        exhaustMap(({ observation, profile, navigateTo }) => {
          const folder: string = getStorageFolder(observation);

          return this.apiService.deleteStorageFile(`/${folder}/${observation.id}.json`).pipe(
            switchMap(() => {
              return from(this.router.navigate([`${$localize.locale}/${navigateTo}`])).pipe(
                map(() => OBSERVATION_UI_ACTIONS.removeSuccess({ observation, profile })),
              );
            }),
            catchError((error) => {
              return of(OBSERVATION_UI_ACTIONS.removeFailed({ error, observation, profile }));
            }),
          );
        }),
      );
    },
    { dispatch: true },
  );

  downloadObservation$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DOWNLOAD_OBSERVATION_ACTIONS.requested),
        switchMap(({ observation, profile }) => {
          return from(downloadJSON(observation, `${observation.resourceType}-${observation.id}.json`)).pipe(
            map(() => DOWNLOAD_OBSERVATION_ACTIONS.succeeded({ observation, profile })),
            catchError((error) => of(DOWNLOAD_OBSERVATION_ACTIONS.failed({ observation, profile, error }))),
          );
        }),
      );
    },
    { dispatch: true },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly apiService: ApiService,
  ) {}
}
