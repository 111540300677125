import { Injectable } from '@angular/core';
import { getSourceType } from '@globals';
import { MixpanelService } from '@logging';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { STORAGE_FILE_API_ACTIONS } from '@store/digi.me';
import { DOWNLOAD_OBSERVATION_ACTIONS, OBSERVATION_UI_ACTIONS } from '@store/hl7fhir';
import { exhaustMap, filter } from 'rxjs/operators';

@Injectable()
export class ObservationsEventLogEffects {
  addObservationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          STORAGE_FILE_API_ACTIONS.addStorageFileSucceeded,
          STORAGE_FILE_API_ACTIONS.addAppleHealthStorageFileSucceeded,
        ),
        filter(({ file }) => file.resourceType === 'Observation'),
        exhaustMap(({ file, measurementType }) => {
          const properties = {
            type: file.resourceType,
            profile: measurementType,
            source: getSourceType(file),
          };

          return this.mixpanelService.trackEvent('record_add_success', properties);
        }),
      );
    },
    { dispatch: false },
  );

  addObservationFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(STORAGE_FILE_API_ACTIONS.addStorageFileFailed),
        filter(({ file }) => file.resourceType === 'Observation'),
        exhaustMap(({ error, file, measurementType }) => {
          const properties = {
            type: file.resourceType,
            profile: measurementType,
            source: getSourceType(file),
            ...this.mixpanelService.getErrorEventProperties(error),
          };

          return this.mixpanelService.trackEvent('record_add_failure', properties);
        }),
      );
    },
    { dispatch: false },
  );

  removeObservationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OBSERVATION_UI_ACTIONS.removeSuccess),
        exhaustMap(({ observation, profile }) => {
          const properties = {
            type: observation.resourceType,
            source: getSourceType(observation),
            profile,
          };

          return this.mixpanelService.trackEvent('record_delete_success', properties);
        }),
      );
    },
    { dispatch: false },
  );

  removeObservationFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OBSERVATION_UI_ACTIONS.removeFailed),
        exhaustMap(({ error, observation, profile }) => {
          const properties = {
            type: observation.resourceType,
            source: getSourceType(observation),
            profile,
            ...this.mixpanelService.getErrorEventProperties(error),
          };

          return this.mixpanelService.trackEvent('record_delete_failure', properties);
        }),
      );
    },
    { dispatch: false },
  );

  downloadObservationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DOWNLOAD_OBSERVATION_ACTIONS.succeeded),
        exhaustMap(({ observation, profile }) => {
          const properties = {
            type: observation.resourceType,
            source: getSourceType(observation),
            profile,
          };

          return this.mixpanelService.trackEvent('record_download_success', properties);
        }),
      );
    },
    { dispatch: false },
  );

  downloadObservationFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DOWNLOAD_OBSERVATION_ACTIONS.failed),
        exhaustMap(({ observation, profile, error }) => {
          const properties = {
            type: observation.resourceType,
            source: getSourceType(observation),
            profile,
            ...this.mixpanelService.getErrorEventProperties(error),
          };

          return this.mixpanelService.trackEvent('record_download_failure', properties);
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly mixpanelService: MixpanelService,
  ) {}
}
